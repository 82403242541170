
/*-----------FUCK CONFLICTS!!!-------*/
/*---------RESET EVERYTHING!!!!!-----*/

.resource-calendar-wrap *, .resource-calendar-wrap *::after, .resource-calendar-wrap *::before
{
  margin: 0 !important;
  padding: 0  !important;
  width: auto  !important;
  height: auto !important;
  border: none  !important;
  font: sans-serif !important;
  line-height: 1 !important;
  font-size: 10pt !important;
  display: default !important;
  box-sizing: content-box !important;
}

/*------------------------------------*/





.resource-calendar-wrap html, .resource-calendar-wrap body, .resource-calendar-wrap p {
  margin: 0 !important;
  padding: 0 !important;
  font-family: sans-serif !important;
  font-size: 10pt !important;
}

.resource-calendar-wrap .cell {
  margin: 2px 1px !important;
  display: inline-block !important;
  padding: 3px !important;
  height: 22px !important;
  overflow: hidden !important;
  cursor: default !important;
}

.resource-calendar-wrap .cell > p {
  vertical-align: middle !important;
  display: inline-block !important;
  width: 100% !important;
  height: 100% !important;
  line-height: 25px !important;
}

.resource-calendar-wrap .day {
  background-color: #E8DDC8 !important;
  color: #444444 !important;
  width: 50px !important;
  text-align: center !important;
}

.resource-calendar-wrap .calendar-labels .cell {
  background-color: #FFFFFF !important;
  color: #444444 !important;
  height: 35px !important;
  width: 50px !important;
  text-align: center !important;
}


.resource-calendar-wrap .calendar-labels.top .cell {
  height: 22px !important;
}

.resource-calendar-wrap .calendar-labels .cell > p {
  line-height: 18px !important;
}


.resource-calendar-wrap .calendar-labels .cell .day-label {
  font-size: .9em !important;
}

.resource-calendar-wrap .day.neutral {
  background-color: #EFE8E1 !important;
  color: #888888 !important;
}

.resource-calendar-wrap .day.negative {
  background-color: #FFDCC2 !important;
  color: #F15A29 !important;
}


.resource-calendar-wrap .day.out-range {
  background-color: #FFFFFF !important;
  border: 1px Solid #E8DDC8 !important;
  width: 48px !important;
  height: 20px !important;
  color: #A59D93 !important;
}

.resource-calendar-wrap .day:hover {
  background-color: #D3C8B8 !important;
}

.resource-calendar-wrap .day:active, .resource-calendar-wrap .day.selected {
  background-color: #A59D93 !important;
}

.resource-calendar-wrap .day.negative:hover {
  background-color: #F2C0AA !important;
}

.resource-calendar-wrap .day.negative:active, .resource-calendar-wrap .day.negative.selected {
  background-color: #CCA39B !important;
}

.resource-calendar-wrap .day.out-range:hover {
  background-color: #EFE8E1 !important;
}

.resource-calendar-wrap .day.out-range:active, .resource-calendar-wrap .day.out-range.selected {
  background-color: #E8DDC8 !important;
}

.resource-calendar-wrap .sab {
  background-color: #878788 !important;
  text-align: left !important;
  color: #FFFFFF !important;
}

.resource-calendar-wrap .sab > p {
  margin-left: 5px !important;
}

.resource-calendar-wrap .sab.active {
  background-color: #FFC700 !important;
  color: #444444 !important;
}

.resource-calendar-wrap .sab:hover {
  background-color: #A5A5A6 !important;
}
.resource-calendar-wrap .sab.active:hover {
  background-color: #F9D766 !important;
}

/* seem to alternate between 61 & 62?*/

.resource-calendar-wrap .cell.length-1 {
  width: 50px !important;
}
.resource-calendar-wrap .cell.length-2 {
  width: 112px !important;
}
.resource-calendar-wrap .cell.length-3 {
  width: 173px !important;
}
.resource-calendar-wrap .cell.length-4 {
  width: 235px !important;
}
.resource-calendar-wrap .cell.length-5 {
  width: 296px !important;
}
.resource-calendar-wrap .cell.length-6 {
  width: 358px !important;
}
.resource-calendar-wrap .cell.length-7 {
  width: 419px !important;
}


.resource-calendar-wrap .week-row {
  text-align: left !important;
  white-space: nowrap !important;
}

.resource-calendar-wrap .week-container {
  display: inline-block !important;
  margin: 0 15px !important;
}

.resource-calendar-wrap .person-calendar, .resource-calendar-wrap .calendar-labels {
  text-align: left !important;
  white-space: nowrap !important;
  /*margin: 0 0 40px 0;*/
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.resource-calendar-wrap .calendar-labels {
  margin: 5px 0 10px 0 !important;
}

.resource-calendar-wrap .calendar-labels.top {
  margin: 20px 0 0 0 !important;
}

.resource-calendar-wrap td {
  padding: 0 0 40px 0 !important;
}

.resource-calendar-wrap td.labels {
  padding: 0 0 5px 0 !important;
}

.resource-calendar-wrap td.person-name {
  text-align: center !important;
  color: #444444 !important;
  padding-left: 10px !important;
  vertical-align: middle !important;
}

