@import 'colours';
@import 'mixins';

.coincraft-table.spreadsheet {
  box-shadow: 9px 7px 17px -4px #ccc;
  background-color: white;
  font-size: 0.9em;
  margin: 4em 0;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.coincraft-table-row.spreadsheet {
  display: flex;
}

.coincraft-table-row.spreadsheet:not(.header) {
  background-color: #fafafa;
}

.coincraft-table-row.spreadsheet.sub-total {
  background-color: white;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.coincraft-table-row.spreadsheet.sub-total .coincraft-table-cell {
  font-weight: bold;
  padding: 1em 0.5em;
}

.coincraft-table-row.spreadsheet.child {
  background-color: #f7f7f7;
  border-bottom: solid 1px #ddd;
}

.coincraft-table-row.spreadsheet.childchild {
  background-color: #ececec;
  border-bottom: solid 1px #d3d3d3;
}

.coincraft-table-row.spreadsheet.childchildchild {
  background-color: #dcdcdc;
  border-bottom: solid 1px #ccc;
}

.coincraft-table-row.spreadsheet.shadow {
  box-shadow: inset 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
}

.coincraft-table-row.spreadsheet.child:last-child {
  border-bottom: solid 1px #ccc;
}

.coincraft-table-row.spreadsheet.childchild:last-child {
  border-bottom: solid 1px #bbb;
}

.coincraft-table-row.spreadsheet.childchildchild:last-child {
  border-bottom: solid 1px #aaa;
}

.coincraft-table-row.spreadsheet.child .coincraft-table-cell:first-child {
  padding-left: 2em;
}

.coincraft-table-row.spreadsheet.childchild .coincraft-table-cell:first-child {
  padding-left: 4em;
}

.coincraft-table-row.spreadsheet.childchildchild .coincraft-table-cell:first-child {
  padding-left: 6em;
}

.coincraft-table-row.spreadsheet:focus {
  outline: none;
}

.coincraft-table-cell.spreadsheet {
  width: 6%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.coincraft-table-cell.spreadsheet.editable {
  cursor: pointer;
}

.coincraft-table-cell.spreadsheet.notvisible {
  opacity: 0.5;
}

.coincraft-table-cell.spreadsheet.pasttime {
  background-image: url(/diag.png);
}

.coincraft-table-cell.spreadsheet .content{
  z-index: 1;
}

.coincraft-table-cell.spreadsheet input{
  height: calc(100% + 1em);
  width: calc(100% + 4em);
  position: absolute;
  top: -0.5em;
  left: -2em;
  text-align: center;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
  outline: solid 2px hsla(200, 50%, 40%, 1);
  border: none;
  padding: 1em;
  z-index: 2;
  background-color: hsla(200, 50%, 96%, 1)
}

.coincraft-table-cell.spreadsheet.rowheader {
  width: 14%;
  justify-content: flex-start;
  .fa {
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}
.coincraft-table-cell.spreadsheet.rowheader.columnheader .content{
  width: 100%;
}

.coincraft-table-cell.spreadsheet.project.inrange {
  border-bottom: solid 3px #ddd;
}

.coincraft-table-cell.spreadsheet.project.scheduleinrange {
  border-bottom: solid 3px #3dacaf;
}

.coincraft-table-cell.spreadsheet.neutral {
  color: #aaa;
}

.coincraft-table-cell.spreadsheet.project.positive {
  border-bottom: solid 5px gold;
}


.coincraft-table-cell.spreadsheet.negative, .context-menu .error {
  color: #f15a29;
  &.project {
    border-bottom: solid 5px #f15a29;
  }
}

.coincraft-table-cell.spreadsheet.past {
  background-image: url(/diag.png);
}

.coincraft-table-cell.spreadsheet.sub-cell.past {
  background-image: url(/diag.png);
}

.coincraft-table-cell.spreadsheet.editable:hover, .coincraft-table-cell.spreadsheet.selected {
  background-color: hsla(200, 50%, 95%, 1);
}



.coincraft-table-row.spreadsheet:not(.header):hover {
  background-color: #e4f8fd;
}


.coincraft-table-cell.spreadsheet.sub-cell {
  font-size: 0.9em;
  border-left: none;
  border-right: none;
}

.coincraft-table-cell.spreadsheet.sub-cell.description {
  justify-content: flex-end;
  padding-right: 2em;
}

.coincraft-table-cell.spreadsheet.selected {
  outline: solid 5px hsla(200, 50%, 40%, 1);;
  position: relative;
  z-index: 2;
  font-weight: bold;
  box-shadow: 1px 1px 12px 5px hsla(50, 60%, 23%, 0.5);
}

.coincraft-table-cell.spreadsheet.sub-cell.selected {
  outline: solid 4px hsla(200, 50%, 40%, 1);
  box-shadow: 1px 1px 8px 3px hsla(50, 60%, 23%, 0.5);
}

.project-history-menu {
  @include flexbox-container;
  @include flex-direction-column;
  width: 100%;
  height: 100%;
  position: absolute;

  //header
  .project-history-menu__header {
    position: relative;
    width: 100%;
    @include flex-0-0-auto;
    padding: 1em;
  }

  //main contents & sidebar
  .project-history-menu__timeline{
    position: relative; // for absolute positioning inside
    width: 100%;
    height: 100%;
    @include flex-1-1-auto;
    overflow: auto;
    background-color: #fafafa;

    // firefox flexbox overflow nonsense
    // http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
    min-width: 0;
    min-height: 0;
    .timeline-item {
      border-bottom: 1px solid #eee;
      &.past {
        background-image: url(/diag.png);
        border-bottom: 1px solid #ddd;
      }
      &.selected {
        box-shadow: 9px 7px 17px -4px #ccc;
        background-color: white;
        border-top: 1px solid #e9e9e9;
        &.past {
          background-image: url(/diag.png);
        }
      }
    }
  }
  .rw-popup-container {
    max-width: 26em;
  }
}

.spreadsheet-history-menu {
  @include flexbox-container;
  @include flex-direction-column;
  width: 100%;
  height: 100%;
  position: absolute;

  //header
  .spreadsheet-history-menu__header {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-0-0-auto;
    position: relative;
    width: 100%;
    padding: 1em;
    .title {
      @include flex-1-1-auto;
      font-weight: 600;
    }
    .likelihood {
      @include flex-0-0-auto;
      text-align: right;
      padding-left: 2em;
      .text {
        margin-right: 0.5em;
      }
      input {
        min-height: 0;
        font-size: 0.9em;
        width: 3em;
        display: inline-block;
        text-align: right;
      }
    }
  }

  //main contents & sidebar
  .spreadsheet-history-menu__timeline {
    position: relative; // for absolute positioning inside
    width: 100%;
    height: 100%;
    @include flex-1-1-auto;
    overflow: auto;
    background-color: #e8e8e8;

    // firefox flexbox overflow nonsense
    // http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
    min-width: 0;
    min-height: 0;
    .spreadsheet-history-menu__timecard {
      padding: 0.5em 1em 1em 1em;
      margin: 1em;
      box-shadow: 4px 4px 17px -4px #ccc;
      border: 1px solid #e9e9e9;
      background-color: #f5f5f5;
      &.past {
        background-image: url(/diag.png);
        border: 1px solid #d8d8d8;
      }
      &.selected {
        box-shadow: 9px 9px 17px -4px #ccc;
        background-color: white;
        border-left: solid gold 5px;
        &.past {
          background-image: url(/diag.png);
        }
      }
      .date-title {
        font-weight: 600;
        @include flexbox-container;
        @include flex-align-items-center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 0.5em;
        margin-bottom: 1.25em;
        .date {
          @include flex-1-1-auto;
        }
        .hours {
          @include flex-1-1-auto;
          text-align: right;
          input {
              min-height: 0;
              font-size: 0.9em;
              width: 3em;
              display: inline-block;
              text-align: right;
            }
        }
      }
      .timecard-progress {
        margin: 1em 0;
      }
      .timecard-text {
        @include flexbox-container;
        @include flex-align-items-center;
        padding: 0 0.5em;
        > div {
          @include flex-1-1-auto;
        }
        .title-val {
          @include flexbox-container;
          @include flex-align-items-center;
          .title {
            margin-right: 1em;
          }
          .value{
            @include flexbox-container;
            @include flex-align-items-center;
            @include flex-justify-content-end;
            input {
              min-height: 0;
              font-size: 0.9em;
              width: 5em;
              display: inline-block;
            }
          }
        }
        .percent {
          @include flexbox-container;
          @include flex-align-items-center;
          @include flex-justify-content-end;
          text-align: right;
          input {
              min-height: 0;
              font-size: 0.9em;
              width: 3em;
              display: inline-block;
              text-align: right;
            }
        }
      }
      .timecard-details {
        font-size: 0.8em;
        i {
          margin: 0 0.5em;
          font-size: 0.9em;
        }
      }
    }
  }
}

