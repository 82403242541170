@mixin dont-print {
  @media print {
    display: none !important;
  }
}

@mixin flexbox-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flexbox-container {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-align-items-center {
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

@mixin flex-justify-content-center {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin flex-justify-content-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@mixin flex-justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

@mixin flex-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin flex-align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
}

@mixin flex-align-items-flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

@mixin flex-align-items-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

@mixin flex-align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
}

@mixin flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@mixin flex-0-0-0 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0px;
  flex: 0 0 0;
  -webkit-flex: 0 0 0;
}

@mixin flex-1-0-0 {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -webkit-flex: 1 0 0;
}

@mixin flex-0-0-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
}

@mixin flex-0-1-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
}

@mixin flex-1-0-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
}

@mixin flex-1-1-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
}

@mixin flex-2-1-auto {
  -webkit-box-flex: 2;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
  -webkit-flex: 2 1 auto;
}
