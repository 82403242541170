h3 {
  line-height: normal;
}

.modal-header {
  padding: 5px 15px;
  border-bottom: 1px solid #ccc;
}

body {
  font-weight: normal;
  padding-bottom: 100px;
}

.logo2 {
  height: 50px;
  width: 50px;
  text-align: left;
  margin-right: -10px;
  display: inline-block;
}

.logo2.s75 {
  height: 75px;
  width: 75px;
  text-align: left;
  margin: 10px 0 20px ;
  display: inline-block;
}

.logo2 img {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.res-hours {
  text-align: center;
  padding-top: 26px;
}
.res-hours .large {
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 10px
}

.resource-calendar-wrap {
  width: 100%;
  overflow: scroll;
}

.page-header .nav > li > a {
  width: auto !important;
  font-size: 1.1em;
  height: 50px !important;
  vertical-align: middle !important;
  line-height: 50px !important;
}

.page-header .nav > li {
  margin-right: 30px;
}

.page-header .nav {
  margin-top: 10px;
  margin-bottom: -10px;
}

nav {
  padding-top: 0 !important;
}

nav .business {
  text-align: center;
  font-size: 1.2em;
  padding: 5px 0;
  margin-top: 0 !important;
  border-bottom: 1px solid #ddd;
}

.side-nav .new {
  color: #aaa;
  font-style: italic;
}

.side-nav a {
  width: auto !important;
}

.side-nav .project {
  background-color: whitesmoke;
}

.side-nav .panel-collapse li a i[class*="fa"] {
  float: left !important;
  margin-left: -25px !important;
}

.side-nav i[class*="fa"]:hover {
  opacity: .8;
}

.side-nav .panel-collapse .panel-collapse > li a {
  padding-left: 30px !important;
  font-size: .9em;
}

.sidebar {
  /*float: none !important;*/
  margin: 0;
  width: auto !important;
  padding: 0 !important;
}

.sidebar.staff {
  margin-top: 40px;
}


.timesheet th.hours {
  padding-right: 25px;
}

.timesheet {
  padding-bottom: 60px;
}

.timesheet td {
  vertical-align: top !important;
}

.timesheet .hours button.edit {
  display:inline-block;
  font-size:1.3em;
  margin: 0 15px;
  width:35px;
  padding: 5px 10px;
}

.timesheet .hours .add-time {
  display:inline-block;
  width: 150px;
  float:right;
  margin:0;
}

.timesheet .hours input{
  text-align: right;
}

.timesheet .header-buttons {
  float: right;
  font-size: .8em;
}

.timesheet .header-buttons button{
  margin:0 15px 0 50px;
}

.timesheet h4 {
  vertical-align: middle;
  margin-bottom: 50px;
}

.timesheet .day-select {
  text-align: center;
  vertical-align: middle;
  margin: 60px 0 50px 0;
}

.timesheet .day-select td{
  border-top: none;
  border-right: solid 1px #aaa;
  width: 12%;
}



.timesheet .day-select td:last-child{
  border-right: none;
}

.timesheet .day-select th{
  border-top: none;
  text-align: center;
  padding-bottom: 25px;
}

.timesheet .day-select td:hover, .timesheet .day-select td.active{
  background-color: #eee;
}

.timesheet .day-select .arrow{
  font-size: 2.2em;
  width: 8%;
  cursor:pointer;
}

.timesheet .day-select .arrow:hover{
  font-size: 3em;
  background-color: white;
  color: #444;
}

/*had to do this inline to work*/
.timesheet tbody tr:last-child td {
  border-bottom: solid 1px #ddd important!;
}


.rw-placeholder {
  color: inherit;
}
