/**
 * login.css
 * @Author Original @msurguy -> http://bootsnipp.com/snippets/featured/parallax-login-form
 * @Reworked By @kaptenn_com
 * @package PARALLAX LOGIN.
 */

.form-signin input[type="text"] {
    margin-bottom: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.centre-text {
    text-align: center;
}

.form-signin .form-control {
    position: relative;
    font-size: 16px;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.vertical-offset-100 {
    padding-top: 100px;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}

.panel {
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

#header-div a {
    color: white !important;
}

.pull-right {
    margin-right: 5px;
}

form.form-signin #username {
    margin-top: 5px;
}

form.form-forgot-password #email {
    margin-top: 4px;
}

.centre-text {
    text-align: center;
}
