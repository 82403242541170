// just import for mixins and variables
// for some reason not all styles are imported
// better to use bundling for everything else
@import 'mixins';
@import 'colours';

.coincraft-table {
  box-shadow: 9px 7px 17px -4px #ccc;
  background-color: white;
}

.coincraft-table-row {
  border-bottom: solid 1px #eee;
  &.header {
    font-weight: bold;
    background-color: #ffd333;
    color: #555;
    border-bottom: none;
    .coincraft-table-cell {
      border-right: none;
      &:first-child {
        border-left: none;
      }
    }
  }
}

.coincraft-table-sub-section {
  box-shadow: inset 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  border-bottom: solid 1px #ccc;
  border-top: solid 1px #ccc;
  & .coincraft-table-row {
    background-color: transparent;
    border-bottom: solid 1px #ddd;
    &:last-child {
      border-bottom: none;
    }
  }
}

.coincraft-table-cell {
  padding: 0.5em;
  border-right: solid 1px #eee;
  color: #777;
  cursor: default;
  &:first-child {
    border-left: solid 1px #ddd;
  }
  &:last-child {
    border-right: none;
    // border-left: solid 1px #ccc;
  }
  &.text-link, & .text-link {
    cursor: pointer;
    &:hover {
      color: #444;
      text-decoration: underline;
    }
  }
}

.coincraft-table-cell--input, .coincraft-table-cell--textarea {
  color: #444;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: inset 6px 6px 13px -10px rgba(0, 0, 0, 0.22);
  &:hover, &.selected {
    background-color: transparent;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2)
  }
}

.coincraft-table-cell--input {
  cursor: text;
  padding: 0.25em;
  @include flexbox-container;
  @include flex-space-between;
  @include flex-align-items-center;
  & .coincraft-table-cell__symbol{
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    padding: 0.25em;
  }

  & .coincraft-table-cell--input__container{
    cursor: text;
    padding: 0;
    @include flexbox-container;
    @include flex-space-between;
    @include flex-align-items-center;
    width: 100%;
    height: 100%;
  }

  .coincraft-table-cell__input {
    height: 100%;
    width: 100%;
    border: none;
    box-shadow: none;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    min-width: 0;
    background: transparent;
    &:focus {
      box-shadow: none;
    }
  }
}

.coincraft-table-cell--currency {
  padding: 0.25em;
  @include flexbox-container;
  @include flex-space-between;
  @include flex-align-items-center;

  & .coincraft-table-cell--currency__container{
    padding: 0em;
    @include flexbox-container;
    @include flex-space-between;
    @include flex-align-items-center;
    width: 100%;
    height: 100%;
  }

  & .coincraft-table-cell__symbol{
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    padding: 0.25em;
  }
  & .coincraft-table-cell__value {
    text-align: right;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    padding: 0.25em;
    &:focus {
      box-shadow: none;
    }
  }
}

.coincraft-table-cell--textarea {
  cursor: text;
  padding: 0;
  & textarea {
    height: 100%;
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0.5em;
    background: transparent;
  }
}

.coincraft-table-cell--date {
  padding: 0;
  & .rw-datetimepicker {
    border: none;
    border-radius: 0;
    height:100%;
    background:transparent;
    &:hover{
      background:transparent;
    }
    & input {
      height:100%;
      background-color: rgba(0, 0, 0, 0.01);
      box-shadow: inset 4px 5px 14px -11px #313131;
      border-radius: 0;
      &:hover {
        background-color: transparent;
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
      }
    }
    & .rw-select {
      border-left: #eee 1px solid;
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}

.coincraft-table-cell--contact {
  padding: 0;
  & > div {
    width: 100%;
    height: 100% !important;
    & > div:not(.contact-modal) {
      width: 100%;
      height: 100%;
      & > div {
        height: 100%;
        & button {
          border: none;
          border-radius: 0 !important;
          border-right: solid 1px #ccc;
          background: transparent;
        }
      }
      & > div:last-child {
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        & > div {
          height: 100%;
          & .rw-dropdownlist{
            height: 100%;
            @include flexbox-container;
            @include flex-align-items-center;
            border: none;
            border-radius: 0;
            & .rw-btn {
              @include flexbox-container;
              @include flex-space-around;
              @include flex-align-items-center;
            }
          }
        }
      }
    }
  }
}

.coincraft-table-cell--tax-dropdown, .coincraft-table-cell--dropdown {
  padding: 0;
  cursor: pointer;
  & > div {
    width: 100%;
    height: 100%;
    & .rw-dropdownlist{
      height: 100%;
      @include flexbox-container;
      @include flex-align-items-center;
      border: none;
      border-radius: 0;
      background-color: transparent;
      & .rw-btn {
        @include flexbox-container;
        @include flex-space-around;
        @include flex-align-items-center;
      }
    }
  }
  &:hover {
    background-color: #eee;
  }
}

.coincraft-table-cell--btn {
  @include flexbox-container;
  @include flex-space-around;
  @include flex-align-items-center;
  padding: 0;
  cursor: pointer;
  & button {
    background-color: transparent;
    height: 100%;
    width: 100%;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: #eee;
  }
}

.coincraft-table-cell--text {
  @include flexbox-container;
  @include flex-justify-content-start;
  @include flex-align-items-center;
  & > * {
    padding-left: 0.5em;
  }
}

input[type="text"]:focus {
  // Fix browser borking up outlines on focus for whatever reason.
  outline-offset: 0 !important;
}
