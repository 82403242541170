.calendar,
.calendar > *:not(.not-calendar),
.calendar > *:not(.not-calendar) * {
	position: relative;
}

.calendar .calendar-item {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	position: absolute;
	background: blue;
	color: white;
	padding: 0.5em;
	border-radius: 0.5em;
	border: solid 1px hsla(0deg, 0%, 100%, 0.75);
	z-index: 0;
	font-weight: 600;
	font-size: 1em;
	overflow: hidden;
	&.allocation {
		background: #666;
	}
	& > * {
		font-size: 0.9rem;
	}
	&.right {
		z-index: 3;
	}
	&.left {
		z-index: 2;
	}
	&:hover {
		z-index: 2;
		&.right {
			z-index: 5;
		}
		&.left {
			z-index: 4;
		}
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
		border-radius: 0.5rem;
		border: solid 1px white;
		z-index: -1;
	}
	.time {
		font-size: 1.1rem;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0.25rem 0.75rem;
		font-weight: bold;
		background: blue;
		width: 100%;
	}

	&.allocation {
		background: #666;
		.time {
			background: #666;
		}
	}
	&.selected {
		background: #00c1ff;
		.time {
			background: #00c1ff;
		}
		&.allocation {
			background: #638bab;
			.time {
				background: #638bab;
			}
		}
	}
	.ant-select {
		font-size: 1.05rem;
		font-weight: bold;
		margin: -1rem 0;
		.ant-select-selector {
			padding: 0;
			background: transparent;
			color: white;
			border: none;
			cursor: pointer;
		}
		.ant-select-arrow {
			font-size: 0.9rem;
			position: absolute;
			background: transparent;
			color: white;
			right: 0;
			font-weight: bold;
		}
		.ant-select-selection-search {
			left: 0.5rem;
			right: 1.5rem;
		}
		.ant-select-selection-search-input {
			background: transparent;
			color: white;
			border: none;
			font-size: 1rem;
		}
	}
}

// .calendar .calendar-item-wrapper {
//     .hover-btns {
//         // position: absolute;
//         font-size: 2em;
//         // right: -1em;
//         color: red;
//         z-index: 10;
//     }
// }

.calendar-item-dropdown {
	width: 16rem !important;
	.ant-select-item-option {
		font-size: 1rem;
		padding: 1rem;
		min-height: 1rem;
		line-height: 1rem;
	}
	.ant-select-item-group {
		font-size: 1rem;
		padding: 0.5rem;
	}
}

.calendar .vertical-drag {
	cursor: ns-resize;
	z-index: 10;
}
