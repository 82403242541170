@import 'colours';
@import 'mixins';
@import '~antd/dist/antd.css';


// .coincraft-page-layout {
// >  .coincraft-page-layout__header {} ------------ must be direct child of .coincraft-page-layout, no siblings
// >  .coincraft-page-layout__content { ------------- can't have wrappers, not siblings
//    >  .coincraft-page-layout__page { ------------------ can't have wrappers, not siblings
//       >  .coincraft-page-layout__page-header {} ----------- must be direct child of .coincraft-page-layout__page, no siblings
//       >  .coincraft-page-layout__page-body {} ------------- must be direct child of .coincraft-page-layout__page, no siblings
//    >  .coincraft-page-layout__sidebar {} ------------- must be direct child of .coincraft-page-layout__content, no siblings

.coincraft-page-layout {
  @include flexbox-container;
  @include flex-direction-column;
  position: absolute;
  width: 100%;
  height: 100%;
  @media print {
    display: block;
    height: auto;
  }

  //header
  .coincraft-page-layout__header {
    position: relative;
    width: 100%;
    @include flex-0-0-auto;
    @media print {
      display: none;
      height: auto;
    }
  }

  //main contents & sidebar
  .coincraft-page-layout__content{
    position: relative; // for absolute positioning inside
    width: 100%;
    height: 100%;
    @include flex-1-1-auto;
    @include flexbox-container;

    // firefox flexbox overflow nonsense
    // http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
    min-width: 0;
    min-height: 0;

    //main page (to left of sidebar)
    .coincraft-page-layout__page {
      position: relative;

      height: 100%;

      // Otherwise IE11 makes the element like a million pixels wide
      width: 100%;

      @include flex-1-1-auto;
      @include flexbox-container;
      @include flex-direction-column;
      @media print {
        display: block;
        height: auto;
      }

      //page header
      .coincraft-page-layout__page-header {
        position: relative;
        width: 100%;
        @include flex-0-0-auto;
      }

      //page-body
      .coincraft-page-layout__page-body {
        position: relative;
        width: 100%;
        height: 100%;
        @include flex-1-1-auto;
        overflow: auto;
        padding-bottom: 7em; // for intercom
      }
    }

      //sidebar
    .coincraft-page-layout__sidebar {
      position: relative;
      height: 100%;
      @include flex-0-0-auto;
      @include flexbox-container;
      max-width: 25em;
      border-left: 1px solid hsla(0, 0%, 85%, 1);
      //using this?
      .coincraft-page-layout__sidebar-toggle {
      @include flex-0-0-auto;
        width: 2em;
        background-color: grey;
      }
      .coincraft-page-layout__sidebar-content {
      padding-bottom: 7em; // for intercom
      @include flex-1-1-auto;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}


.save-bar {
  width: 100%;
  text-align: center;
  background: #009a81;
  color: white;
  padding: .5em;
  font-weight: 600;
  display: inline-block;
  width: 35%;
  border-radius: 0.5em;
  pointer-events: visible;

  .save-bar__save-button {
    border-radius: 6px;
    background: #FFFFFF;
    border: solid #cccccc 1px;
    margin: 0 1em;
    color: #444;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }

  .save-bar__close-button {
    float: right;
    background: transparent;
    border: none;
    font-size: 1.2em;
    padding: 0;
    color: white;

    i {
      padding: 0;
      margin: 0;
    }

    &:hover {
      background: transparent;
      border: none;
      color: #444;
    }
  }
}

.page-header__button {
  background-color: transparent;
  margin: 0.5em;
  color: white;
  padding: 0.5em 1.5em;
  font-size: 0.95em;
  border-radius: 0.4em;
  border: solid 1px white;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.page-header__button--primary {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0.5em;
  color: #444;
  padding: 0.5em 1.5em;
  font-size: 0.95em;
  border-radius: 0.4em;
  border: solid 1px white;
  cursor: pointer;
  &:not([disabled]):hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.coincraft-page-layout__page-header {
  min-height: 10em;
  background-color: #444;
    padding: 1.5em 1.5em 0 1.5em;
  color: white;
  position: relative;
  @media print {
    background-color: white;
    color: #333;
    min-height: 0;
  }
  .heading {
    font-size: 3em;
    line-height: 1em;
    font-weight: 300;
    display: inline-block;
    margin-right: 0.5em;
  }
  .buttons, button {
    display: inline-block;
    @include dont-print;
  }
  .search-field {
    position: relative;
    display: inline-block;
    padding: 0.5em;
    @include dont-print;
    i.fa-search {
      position: absolute;
      left: 1em; //padding + margin
      top: calc(50% - 0.5em); //50% -height?
      padding: 0;
      margin: 0;
      height: 0.5em;
      width: 0.5em;
    }
    input[type="search"] {
      background-color: transparent;
      color: white;
      padding: 0.5em 2em;
      font-size: 0.95em;
      border-radius: 0.4em;
      border: solid 1px white;
      cursor: text;
      width: 18em;
      padding-right: 0.5em;
      margin: 0;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .report-selector {
    @include dont-print;
    margin-top: 1.25em;
    @include inline-flexbox-container;
    @include flex-align-items-center;
    .report-text {
      font-size: 1em;
      margin-right: 0.5em;
    }
    button {
      background-color: transparent;
      margin: 0.5em;
      color: white;
      padding: 0.25em 1em;
      font-size: 0.95em;
      border-radius: 0.4em;
      border: solid 1px white;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
      i.fa {
        margin: 0;
        margin-left: 1em;
      }
    }
    button.primary {
      background-color: rgba(255, 255, 255, 0.9);
      color: #444;
      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
    .rw-widget {
      background-color: transparent;
      border: #fff 1px solid;
      border-radius: 0.4em;
      padding-top: 0.25em;
      padding-bottom: 0.25em;
      font-size: 0.9em;
      line-height: normal;
      color: white;
      .rw-input, .rw-dropdownlist-picker {
        height: auto;
        color: white;
        line-height: normal;
        background-color: transparent;
      }
      .rw-dropdownlist-picker {
        display: inline-block;
        padding-top: 0.25em;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
      .rw-filter-input {
        margin: 0.25em 0;
        background-color: rgba(255, 255, 255, 0.1);
        input {
          background-color: transparent;
          color: white;
        }
        .rw-btn {
          color: white;
          background-color: transparent;
          border: none;
          margin-top: 0;
        }
      }
      .rw-popup-container {
        width: 25em;
        .rw-popup {
          margin-top: 0.5em;
          background-color: #444;
          font-size: 1em;
          padding: 0.25em 0.5em;
          ul {
            padding: 0;
            width: 100%;
          }
          .rw-list-option {
            margin: 0.25em 0;
            border: none;
            color: #fff;
            div {
              padding: 0.25em;
            }
            &.rw-state-selected {
              background-color: #848484;
              border: none;
              color: #fff;
            }
            &.rw-state-focus {
              background-color: transparent;
            }
            &:hover {
              background-color: #636363;
              border: none;
            }
          }
        }
      }
    }
  }
  .rw-multiselect {
    padding: 0.25em 0.5em 0;
  }
  .rw-multiselect-taglist > li {
    background-color: hsla(0, 0%, 44%, 1);
    border-color: hsla(0, 0%, 44%, 1);
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 1.25em;
    position: relative;
    span {
          margin: 0.15em;
          right: 0;
          position: absolute;
          top: 0;
    }
  }
}

.page-layout__tabs {
  @include dont-print;
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 2em;
  .tab {
    display: inline-block;
    padding: 0.75em 2em;
    font-size: 0.95em;
    margin: 0 1px;
    color: #444;
    background-color: #ffd333;
    cursor: pointer;
    &.highlighted {
      background-color: #fafafa;
    }
  }
}

.coincraft-page-layout__page-body {
  padding: 2em;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  @media print {
    box-shadow: none;
    padding: 2em 0 0 0;
  }
}

//ie doesn't clip by default
svg {
  overflow: hidden;
}

.cc-alert {
  width: 100%;
  text-align: center;
  background: #00a3af;
  color: #fff;
  padding: .5em;
  font-weight: 600;
}

.cc-alert.red-alert {
  background: #FF491A;
  color: #2f2f2f;
}

.cc-alert button {
  border-radius: 6px;
  font-size: .8em;
  background: #FFFFFF;
  padding: 0em 0.8em;
  border: solid #cccccc 1px;
  margin: 0 .5em;
  font-weight: 600;
}

.cc-alert button:hover {
  background: #f0f0f0;
}

.cc-alert .close-btn {
  float: right;
  background: transparent;
  border: none;
  font-size: 1.2em;
  padding: 0;
  color: #fff;
}

.cc-alert .close-btn i{
  padding: 0;
  margin: 0;
}

.cc-alert .close-btn:hover {
  background: transparent;
  border: none;
  color: #444;
}

.coincraft-page-layout__page-body {
  background-color: #fafafa;
}

.print-a4-landscape {
    width: 265mm;
}
