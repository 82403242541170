.invoice-page {
    font-size: 0.9em;
    width: 21cm;
    height: 29.7cm;
    box-sizing: border-box;
    background: white;
    overflow: hidden;
    margin: 1em;
    padding: 1cm;
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 0.25em 0.25em 1em rgba(75, 75, 75, 0.2);
    white-space: pre-line;
    .inv-bold {
        font-weight: 600;
        color: #444;
    }
    &>.header {
        text-transform: uppercase;
        padding: 1em;
        margin-bottom: 2em;
        display: grid;
        grid-template-areas: 
            ". logo logo"
            "project logo logo"
            "project logo logo"
            "client logo logo"
            "client issue ."
            "number due amount";
        .logo {
            grid-area: logo;
            margin: 0;
            align-self: start;
            justify-self: end;
        }
        .project-info {
            grid-area: project;
            margin-right: 2em;
            align-self: end;
            margin-bottom: 1em;
            line-height: 1.5em;
        }
        .client-info {
            grid-area: client;
            margin-right: 2em;
            align-self: end;
            margin-bottom: 0.75em;
            line-height: 1.5em;
        }
        .issue-date {
            grid-area: issue;
            margin-right: 2em;
            align-self: end;
            margin-bottom: 0.75em;
            line-height: 1.5em;
        }
        .inv-number {
            grid-area: number;
        }
        .due-date {
            grid-area: due;
        }
        .amount-due {
            grid-area: amount;
        }
        .inv-highlight {
            text-transform: uppercase;
            padding: 1.25em 0;
            border-top: solid #444 1px;
            border-bottom: solid #444 1px;
            &:not(:last-child) {
                margin-right: 2em;
            }
            .label {
                font-size: 1.2em;
                margin-bottom: 0.5em;
                font-weight: 600;
                color: #444;
            }
            .value {
                font-size: 2em;
            }
        }
    }
    @media print {
        margin: 0;
        box-shadow: none;
        display: flex;
        width: 100vw;
        height: 100vh;
    }
    .invoice-table {
        display: grid;
        .cell {
            padding: 0.75em;
            border-bottom: solid 1px #444;
            &.last {
                border-bottom: none;
            }
            &.header {
                background: #444;
                color: white;
                text-transform: uppercase;
                font-size: 1.1em;
            }
            &.right {
                text-align: right;
            }
            &.total {
                font-size: 1.1em;
                &.label {
                    grid-column: 2 / 4;
                    text-transform: uppercase;
                    padding-right: 3em;
                }
                &.amount {
                    grid-column: 4 / 5;
                }
                &.last {
                    font-weight: bold;
                    border-bottom: none;
                }
            }
            &.add-item {
                border-bottom: none;
            }
        }
        &.invoice-table-totals {
            border-top: solid 2px #444;
        }
    }
    .ordering {
        position: absolute;
        left: -0.7em;
        top: 0.8em;
        font-size: 1.2em; 
        .arrow {
            margin: 0;
            padding: 0;
            position: absolute;
            cursor: pointer;
            opacity: 0.25;
            &:hover {
                opacity: 0.8;
            }
            &.up {
                top: -0.35em;
            }
            &.down {
                top: 0.35em;
            }
        }
    }
}

.coincraft-page-layout__page-body {
    &.grey {
        background-color: #ccc;
        @media print {
            background-color: white;
        }
    }
    &.print-hidden {
        @media print {
            padding: 0 !important;
        }
    }
}
    