.coincraft-page-layout__sidebar section, .context-menu__section {
  padding: 2em;
  padding-right: 3.5em; // for help menu
  border-bottom: solid 1px #ccc;
  h5 {
    font-weight: 600;
  }
}

.context-menu__title-line--small {
  font-weight: 600;
  font-size: 1.2em;
  line-height: normal;
}

.context-menu__title-line--large {
  text-decoration: underline;
  font-size: 1.9em;
  margin: 0.3em 0;
  line-height: normal;
}
