@import 'colours';
@import 'mixins';

.coincraft-header .header-bar {
  margin: 0;
  background-color: $gold;
  padding: 0 2em;
  color: #444;
  @include flexbox-container;
  @include flex-space-between;
  @include dont-print;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.navigation {
  .nav-item {
    position: relative;
    display: inline-block;
    height: 100%;
    white-space: nowrap;
  }
  .nav-button {
    height: 100%;
    display: inline-block;
    padding: 1em 1.5em;
    font-weight: 600;
    cursor: pointer;
    &.highlighted {
      background-image: linear-gradient(hsla(47, 100%, 65%, 1), hsla(47, 100%, 75%, 1));
    }
  }
  .nav-dropdown {
    display: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    top:100%;
	  left:0;
    background-color: hsla(47, 100%, 70%, 1);
  }
  .nav-dropdown-item {
    display: block;
    padding: 1em 2.5em 1em 1.5em;
    border-bottom: solid 1px hsla(47, 100%, 60%, 1);
    border-top: solid 1px hsla(47, 100%, 80%, 1);
    cursor: pointer;
    &:hover, &.highlighted {
      background-image: linear-gradient(hsla(47, 100%, 80%, 1), hsla(47, 100%, 90%, 1));
    }
  }
  //TODO-project_architect_new_layout classify the :hover stuff since we don't
  // rely on that anymore.
  .nav-item:hover, .nav-item:focus {
    .nav-button {
      background-image: linear-gradient(hsla(47, 100%, 65%, 1), hsla(47, 100%, 75%, 1));
    }
  }
}

.account-info {
  text-align: right;
  @include flexbox-container;
  @include flex-align-items-center;
  .user-name, .signout-btn {
    display: inline-block;
    font-size: 0.95em;
    padding: 0.25em 0.5em;
  }
}

.trial-bar {
  background-color: #f15a29;
  text-align: center;
  color: #444;
  font-weight: 600;
  font-size: 0.9em;
  box-shadow: inset 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
  @include dont-print;
}

.subscribe-btn, .subscribe-text {
  display: inline-block;
  font-size: 0.95em;
  padding: 0.25em 0.5em;
}
.subscribe-btn {
  color: white;
  background: #444;
  border-radius: 4px;
  padding: 0.2em 1em 0.1em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  &:hover {
    background: #666;
  }
}
